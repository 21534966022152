<template>
  <div class="page">
    <div class="page__bg"></div>
    <div
      v-show="$route.name === 'store' || $route.name === 'product'"
      class="page__points"
    >
      Доступно
      <span>{{ points | priceFilter }}</span>
      <div class="balance-coins">
        <span class="coin"
          ><img src="@/assets/img/pic2/coin.svg" width="15" height="15" alt=""
        /></span>
        {{ COINS }}
      </div>
    </div>
    <div class="page__inner">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    points: Number,
  },
};
</script>

<style lang="scss" scoped>
.page {
  position: relative;
  overflow: hidden;
  padding-top: $headerHeight;
  padding-bottom: $footerHeight;
  background: $bgColorStore;
  color: #fcdab6;
  &__bg {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: url(~@/assets/img/page/bg/dark.png) 0 0 no-repeat;
    background-size: 100% auto;
  }
  &__points {
    @include bg-decor();
    position: fixed;
    top: 67px;
    right: percentage(155 / 1920);
    z-index: 10;
    width: 287px;
    height: 364px;
    padding: 73px 20px 0;
    font-size: 21px;
    background-image: url(~@/assets/img/pic/field-points.png);
    color: $fontColor;
    text-align: center;
    &::after {
      @include bg-decor();
      content: "";
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 40px;
      width: 114px;
      height: 99px;
      background-image: url(~@/assets/img/pic/money.svg);
    }
    > span {
      display: block;
      font-weight: bold;
      font-family: $fontRafale;
      font-size: 60px;
      color: #814d2b;
      position: relative;
      left: -0.03em;
    }

    .coin {
      display: block;
      margin-right: 5px;
      img {
        width: 21px;
        display: block;
      }
    }
  }
  &__inner {
    min-height: calc(100vh - #{$headerHeight} - #{$footerHeight});
    position: relative;
    padding-top: 95px;
    padding-bottom: 100px;
    &:before {
      background: url(~@/assets/img/pic/watermark-1.png) 0 0 no-repeat;
      position: fixed;
      content: "";
      pointer-events: none;
      bottom: 50px;
      left: 0.75vw;
      width: 6.3vw;
      height: 12vh;
      background-size: contain;
      background-position: 50% 50%;
    }
  }
}
.balance-coins {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
}

@media (max-width: 1750px) {
  .page__points {
    right: 15px;
  }
}
</style>
